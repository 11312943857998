<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1" @click="back" style="border: 1px solid #00FFFF; width: 5rem; border-radius: 1rem;">
        返回
      </div>
      <!-- <div class="h1">

      </div> -->
      <div class="h2" @click="addN">
        添加员工
      </div>
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd" v-for="(item, index) in Data" :key="index">
        <!-- 账号密码 -->
        <div>
          <span class="zh">
            账号：{{ item.account }}
          </span>
          <span class="zh">
            密码：******
          </span>
          <span class="zh">
            姓名：{{ item.name }}
          </span>
        </div>

        <!-- 修改 -->
        <div class="xg">

          <span class="gg" @click="edmm(item)">
            更改密码
          </span>
          <span class="gg" style="width: 4rem; background-color: red;" @click="del(item.id)">
            删除
          </span>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>


    <!-- 新增弹窗 -->
    <el-dialog title="操作" :visible.sync="dialogFormVisible">

      <el-form :model="form" style="margin-top: 1rem;">
        <el-form-item v-if="ed" label="姓名:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="ed" label="账号:" :label-width="formLabelWidth">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码:" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Qx">取 消</el-button>
        <el-button v-if="ed" type="primary" @click="Form()">确 定</el-button>
        <el-button v-if="!ed" type="primary" @click="Form1()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'gsgly',
  data() {
    return {
      dialogFormVisible: false,
      value: true,
      currentPage4: 1,
      obj: {
        "page": 1,
        "size": 10
      },
      total: null,
      Data: [],
      form: {
        name: '',
        account: '',
        password: ''
      },
      formLabelWidth: '12rem',
      ed: true,
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      Tplist: [],
      reportUrl: '',
      id: null
    }
  },
  created() {
    this.id = this.$route.query.id
    console.log(this.id, 'id!!!!!!');
    this.info()
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    Qx() {
      this.dialogFormVisible = false
      this.Tplist = []
      this.dialogImageUrl = []
      this.fileList = []
    },
    onchange(file, fileList) {
      let id = {
        num: null
      }
      // console.log(file, fileList, 'onchange!!!');
      this.fileList = fileList
      let fd = new FormData();
      fd.append('file', file.raw); //传文件
      console.log(fd, 'fd!!!!!');
      this.$ajax('sctp', 'post', fd).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.message
          });
          id = {
            num: res.data.id
          }
          this.Tplist.push(id)
          // this.form.image res.data.id + ''
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
      // this.fileList.push(fileList)
      console.log(this.fileList, 'fileList!!!');
    },
    handleRemove(file, fileList) {
      console.log(file, fileList, '删除');
      this.Tplist = []
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogImageUrl.push(file.url);
      this.dialogVisible = true;
    },
    zt(item, id) {
      if (item.KG) {
        item.state = 0
      } else {
        item.state = 1
      }
      let obj = {
        "id": item.id,
        "state": item.state
      }
      console.log(obj);
      this.$ajax('ztsgr', 'post', obj).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.info()
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    edmm(item) {
      this.form = {
        "id": item.id,
        "password": ''
      }
      this.ed = false
      this.dialogFormVisible = true
    },
    addN() {
      this.Tplist = []
      this.form = {
        "customerId": this.id,
        name: '',
        account: '',
        password: ''
      }
      this.ed = true
      this.dialogFormVisible = true
      this.fileList = []
    },
    Form() {
      this.$newPost('/customer/employee/save', this.form).then(res => {
        if (res.code == 2000) {
          console.log(res, 'new!!!!');
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.info()
          this.dialogFormVisible = false
          this.form = {
            "customerId": this.id,
            name: '',
            account: '',
            password: ''
          }
          // this.Sblb()
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })

      // this.$ajax('addsgr', 'post', this.form).then(res => {
      //   console.log(res);
      //   if (res.code == 2000) {
      //     this.form.head = null
      //     this.fileList = []
      //     this.info()
      //     this.dialogFormVisible = false
      //     this.$message({
      //       type: 'success',
      //       message: res.message
      //     });
      //     this.info()
      //   } else {
      //     this.$message({
      //       type: 'error',
      //       message: res.message
      //     });
      //   }
      // })
      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: '请选择头像'
      //   });
      // }

    },
    Form1() {
      let obj = {
        "id": this.id,
        "password": this.form.password
      }
      this.$newPost('/customer/employee/update', obj).then(res => {
        if (res.code == 2000) {
          console.log(res, 'new!!!!');
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.dialogFormVisible = false
          // this.Sblb()
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })

    },
    del(id) {
      this.$confirm('是否删除此账号?', '提示', {
        confirmButtonText: '取消',
        cancelButtonText: '确定',
        type: 'warning',
        showClose: false
      }).then(() => {
        this.$newDelete('/customer/employee/delete/' + id).then(res => {
          if (res.code == 2000) {
            console.log(res, 'new!!!!');
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.info()
            // this.Sblb()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        }).catch(() => {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        })
      }).catch(err => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      })


    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.obj.size = val
      this.info()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.obj.page = val
      this.info()
    },
    info() {
      let obj = {
        "customerId": this.id,
        "page": 1,
        "size": 10
      }
      this.$newPost('/customer/employee/page', obj).then(res => {
        if (res.code == 2000) {
          console.log(res, 'new!!!!');
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.Data = res.data.records
          this.total = res.data.total
          // this.Sblb()
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
      // this.$ajax('sgryzhgl', 'post', this.obj).then(res => {
      //   console.log(res);
      //   this.Data = res.data.records
      //   this.total = res.data.total
      //   this.Data.forEach(item => {
      //     if (item.state == 0) {
      //       this.$set(item, 'KG', true)
      //     } else {
      //       this.$set(item, 'KG', false)
      //     }
      //   })
      // })
    }
  },
}
</script>

<style lang="scss" scoped>
.m1 {
  position: relative;
  color: #FFFFFF;
  margin: 1rem 0 0 1rem;
  width: 156.6rem;
  // height: 59.4rem;

  .hd {
    position: relative;
    width: 100%;
    height: 5rem;


    .h1 {
      display: inline-block;
      width: 11.5rem;
      height: 5rem;
      line-height: 5rem;
      padding-left: 1rem;
      font-size: 1.8rem;
    }

    .h2 {
      position: absolute;
      top: 1rem;
      right: 2rem;
      width: 11.5rem;
      height: 4.2rem;
      background-color: rgba(43, 201, 222, 1);
      line-height: 4.2rem;
      text-align: center;
      border-radius: .5rem;
      cursor: pointer;
    }
  }

  .lb {
    position: relative;

    border: 1px solid #2BDEC9;
    margin-top: 1rem;
    font-size: 1.4rem;
    // overflow: auto;

    .hd {
      width: 100%;
      height: 3.6rem;
      line-height: 3.6rem;
      // padding-left: 1rem;
      font-size: 1.4rem;
      border-bottom: 1px solid #2BDEC9;

      .zh {
        margin-left: 1rem;
        display: inline-block;
        width: 16.8rem;
        overflow: hidden;
      }
    }

    .hd:last-child {
      border-bottom: none;
    }

    .qx {
      position: absolute;
      top: .1rem;
      left: 50rem;
      display: inline-block;

      .lst {
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        background-color: rgba(43, 201, 222, 0.6);
        text-align: center;
        border-radius: .3rem;
        margin-right: 1rem;
      }
    }

    .xg {
      position: absolute;
      top: .5rem;
      right: 2rem;
      width: 13rem;
      height: 2.6rem;
      line-height: 2.6rem;

      .gg {
        cursor: pointer;
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        text-align: center;
        background-color: rgba(28, 184, 184, 1);
        border-radius: .4rem;
        margin-left: 1rem;
      }
    }
  }
}
</style>
